import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.scss';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../../img/logo.svg';

export default function Navbar ({ visible }) {

    return (
        <div className={`Navbar ${visible && 'visible'}`}>
            <div className="Navbar__Content">

                <div className="location">
                    <a href="https://goo.gl/maps/PUtBqWiYgHU5vNwx9" target="_blank" rel="noopener noreferrer" title="Google Maps">
                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                        &nbsp; 309 Main St N
                        <br />
                        Austin, MN 55912
                    </a>
                </div>

                <div className="logo">
                    <Link to="/">
                        <img src={Logo} alt="Gravity Storm Brewery"/>
                    </Link>
                </div>

            </div>

        </div>
    );
}