import React from 'react';

export default function Item ({ type, pricing }) {
    return (
        <tr className="Item">
            <td>
                {/* {type} {pricing} */}
                {type} <span style={{ float: 'right', marginLeft: '2rem' }}>{pricing}</span>
            </td>
        </tr>
    );
}