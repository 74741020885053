import React from 'react';
import { Link} from 'react-router-dom';
import './Footer.scss';

import Logo from '../../img/logo.svg';

import Media from '../../components/Media/Media.jsx';

export default function Footer () {
    return (
        <footer className="Footer">
                <Link to="/">
                    <img src={Logo} alt="Gravity Storm Brewery" style={{width: '64px'}}/>
                </Link>
                <Media />
                <p>
                    Gravity Storm Brewery &copy;2019. All rights reserved.
                    <br />
                    <Link to="/terms">
                        Terms of Use
                    </Link> and <Link to="/privacy">
                        Privacy Policy
                    </Link>
                </p>
        </footer>
    );
}