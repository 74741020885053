import React, { useState, useEffect } from 'react';
import './PricingTable.scss';

import Chalkboard from '../../../img/ChalkboardBackground.jpg';
import Item from './Item/Item.jsx';

const backup = [
    {
        type: 'Pint',
        pricing: '6.00'
    },
    {
        type: "Mini",
        pricing: "2.00"
    },
    {
        type: "Flight (4 samplers)",
        pricing: "7.00"
    },
    {
        type: "Crowler* (750mL)",
        pricing: "8.00"
    },
    {
        type: "Growler* (64oz)",
        pricing: "14.00"
    },
    {
        type: "Root Beer Glass",
        pricing: "2.00"
    },
    {
        type: "Root Beer Crowler",
        pricing: "5.00"
    },
    {
        type: "Root Beer Growler",
        pricing: "8.00"
    },
    {
        type: "Cans",
        pricing: "1.50"
    }
];

const offsaleBackup = {
    crowlers: '$11',
    growlers: '$18',
};

export default function PricingTable ({ full }) {

    const [items, setItems] = useState([]);
    const [offsale, setOffsale] = useState({});

    useEffect(() => {
        fetch('/get-pricing', { method: 'POST' })
            .then(response =>{
                if (response.status !== 200) return setItems([...backup]);

                response.json().then(result => {
                    if (!result) return setItems([...backup]);
                    setItems([...result]);
                });
            })
            .catch(console.error);

        fetch('/get-offsale', { method: 'POST'})
            .then(response => {
                if (response.status !== 200) return setOffsale(offsaleBackup);

                response.json().then(result => {
                    if (!result.offsale) return setOffsale(offsaleBackup);
                    setOffsale(result.offsale);
                });
            })
            .catch(console.error);
    }, []);

    return (
        <table className="PricingTable" style={{backgroundImage: `url(${Chalkboard})`}}>
            <thead>
                <tr>
                    <th>Pricing</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item, i) => (
                    <Item key={i}
                        type={item.type}
                        pricing={item.pricing}
                    />
                ))}
                <tr style={{ height: '5rem' }}>
                    <td>
                        <i>We serve our pints as 20oz Imperial Pints.</i>
                    </td>
                </tr>
                <tr style={{ height: '5rem' }}>
                    <td>
                        <i>* High Gravity beers are served in a 13oz snifter. Off sale Crowlers are {offsale.crowlers} and Growlers are {offsale.growlers}.</i>
                    </td>
                </tr>
                {full && (
                    <tr>
                        <td>
                            <h3><i>Food</i></h3>
                            <p><i>
                                We have popcorn. But fear not, we are more than
                                happy for you to bring in food from any of the excellent
                                restaurants on Main St. Menus are on the
                                Historical Society display.
                            </i></p>
                        </td>
                    </tr>
                )}
                {full && (
                    <tr>
                        <td>
                            <h3><i>Beer-It-Forward</i></h3>
                            <p><i>
                                Buy a beer for a friend, old or new
                            </i></p>
                            <p><i>
                                Get a card from your bartender and put it on the board to buy a beer for:
                                <br />
                                * someone you know
                                <br />
                                * someone you don't (a veteran, teacher, firefighter, etc.)
                                <br />
                                * something a little different (someone wearing a Buffett shirt, etc.)
                                <br />
                                Make sure to check the board often to see if someone has bought a beer for you.
                                10% of all Beer it Forward purchases go to the Lyle Cancer Auction
                            </i></p>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}
