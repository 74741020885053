import React, { useState, useEffect } from 'react';
import './Notice.scss';

export default function Notice() {

    const [notice, setNotice] = useState({});

    useEffect(() => {
        fetch('/get-notice', { method: 'POST'})
            .then(response => {
                if (response.status !== 200) return setNotice('');

                response.json().then(result => {
                    if (!result.notice) return setNotice('');
                    setNotice(result.notice);
                });
            })
            .catch(console.error);
    }, []);

    return (
        <div className="Notice" style={{display: `${notice ? 'block' : 'none'}`}}>
            <p>
                {typeof notice === 'string' ? notice : ''}
            </p>
            <p><i>
                Gravity Storm Brewery
            </i></p>
        </div>
    );
}
