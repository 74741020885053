import React, { useState, useEffect } from 'react';
import './Hours.scss';

const backup = {
    monday: '4:00 pm - 9:00 pm',
    tuesday: 'Closed',
    wednesday: 'Closed',
    thursday: '4:00 pm - 9:00 pm',
    friday: '3:00 pm - 11:00 pm',
    saturday: '12:00 pm - 11:00 pm',
    sunday: 'Closed',
};

export default function Hours () {

    const [hours, setHours] = useState({});

    useEffect(() => {
        fetch('/get-hours', { method: 'POST'})
            .then(response => {
                if (response.status !== 200) return setHours(backup);

                response.json().then(result => {
                    if (!result.hours) return setHours(backup);
                    setHours(result.hours);
                });
            })
            .catch(console.error);
    }, []);

    return (
        <div className="Hours">
            {/* Needs higher level styling */}
            <p>Monday <span style={{float: 'right'}}>{hours.monday}</span></p>
            <hr />
            <p>Tuesday <span style={{float: 'right'}}>{hours.tuesday}</span></p>
            <hr />
            <p>Wednesday <span style={{float: 'right'}}>{hours.wednesday}</span></p>
            <hr />
            <p>Thursday <span style={{float: 'right'}}>{hours.thursday}</span></p>
            <hr />
            <p>Friday <span style={{float: 'right'}}>{hours.friday}</span></p>
            <hr />
            <p>Saturday <span style={{float: 'right'}}>{hours.saturday}</span></p>
            <hr />
            <p>Sunday <span style={{float: 'right'}}>{hours.sunday}</span></p>
        </div>
    );
}
