import React, { useEffect } from 'react';
import './Modal.scss';

export default function Modal ({ visible = false, start, time, title, link, onClose }) {

    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    });

    function keydownHandler({ key }) {
        switch (key) {
            case 'Escape':
                onClose();
                break;
            default:
        }
    }

    return !visible ? null : (
        <div className="Modal" onClick={onClose}>
            <div className="Modal-Dialog" onClick={e => e.stopPropagation()}>

                <span className="Modal-Close" onClick={onClose}>
                    &times;
                </span>

                <div className="Modal-Header">
                    <h3>{title}</h3>
                </div>

                <div className="Modal-Body">
                    <div className="Modal-Content">
                        <p>{start}</p>
                        <p><i>{time}</i></p>
                        {link && (
                            <a href={link} className="btn" target="_blank" rel="noopener noreferrer">
                                More Information
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
