import React, { useState, useRef } from 'react';
import './ContactForm.scss';

const optional = {
    opacity: '.5',
    fontStyle: 'italic',
    fontSize: '1rem',
};

export default function ContactForm () {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [mtimer, setMtimer] = useState(null);
    const messageDOM = useRef(null);

    function HandleSubmit(evt) {
        evt.preventDefault();

        if (!name || !email) {
            return HandleMessage('Please fill required fields', 'warning');
        }

        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return HandleMessage('Invalid email address', 'warning');
        }

        // POST w/ JSON
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/contact');
        xhr.setRequestHeader('Content-Type', 'application/json');

        // RESPONSE EVENT
        xhr.onload = () => {
            if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                if (response.error)  {
                    console.error('Response Error', response.error);
                    return HandleMessage('Error connecting to server', 'warning');
                }

                // Handle Data
                console.log(response);
                alert('Thank you! I\'ll be in touch.');
                HandleMessage('Thank you! We\'ll be in touch.', 'success');

                setName('');
                setEmail('');
                setSubject('');
                setMessage('');
            } else {
                console.warn('Check Request Status', xhr.status);
                return HandleMessage('Error connecting to server', 'error');
            }
        };

        // SEND DATA
        return xhr.send(JSON.stringify({ name, email, subject, message }));
    }

    function HandleMessage(text, type) {
        let notification = messageDOM.current;

        notification.classList.remove('success', 'warning', 'error');
        clearTimeout(mtimer);

        // Set message text
        notification.textContent = text;

        // Set message class
        notification.classList.add(type, 'live');

        // Set dead -- 5 sec
        setMtimer(setTimeout(() => {
            notification.classList.remove(type);
            notification.classList.remove('live');
        }, 5000));
    }

    return (
        <div className="ContactForm">

            <form className="ContactForm__Form">

                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name"
                    value={name}
                    onChange={evt => setName(evt.target.value)}
                    onBlur={evt => setName(evt.target.value)}
                />

                <label htmlFor="email">Email</label>
                <input type="email" name="email" id="email"
                    value={email}
                    onChange={evt => setEmail(evt.target.value)}
                    onBlur={evt => setEmail(evt.target.value)}
                />

                <label htmlFor="subject">Subject <span style={optional}>- Optional</span></label>
                <input type="text" name="subject" id="subject"
                    value={subject}
                    onChange={evt => setSubject(evt.target.value)}
                    onBlur={evt => setSubject(evt.target.value)}
                />

                <label htmlFor="message">Message <span style={optional}>- Optional</span></label>
                <textarea name="message" id="message" cols="1" rows="4"
                    value={message}
                    onChange={evt => setMessage(evt.target.value)}
                    onBlur={evt => setMessage(evt.target.value)}
                />

                <button type="submit" className="btn"
                    onClick={HandleSubmit}
                >Send Message</button>

                <p className="message" ref={messageDOM}></p>
            </form>
        </div>
    );
}
