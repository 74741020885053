import React from 'react';
import './ScrollToTop.scss';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default function ScrollToTop ({ visible }) {

    const scrollToTop = () => document.body.scrollIntoView({ behavior: 'smooth', });

    return (
        <button className={`ScrollToTop ${visible && 'visible'}`}
            type="button"
            aria-label="Scroll To Top"
            onClick={scrollToTop}
        >
            <FontAwesomeIcon icon={faChevronUp} />
        </button>
    );
}
