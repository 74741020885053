import React from 'react';
import './CallToAction.scss';

// import Icon from '../../img/logo_black.svg';
import BeerImg from '../../img/beer2_clean_cropped.png';
// import Beer from './Beer/Beer.jsx';

import Background from '../../img/background.jpg';

export default function CallToAction () {
    return (
        <div className="CallToAction" style={{backgroundImage: `url(${Background})`}}>
            {/* <div className="CallToAction__Titles">
                <h2 className="title1">
                    Come and taste
                </h2>

                <h2 className="title2">
                    The Finest Selection of Beer.
                </h2>
            </div> */}

            <div className="CallToAction__Beer">
                <div className="CallToAction__Beer__Selection1">
                    {/* Slide in left/right */}
                    {/* <Beer
                        icon={Icon}
                        title="Porter"
                        description="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Optio nostrum eligendi nemo unde eos inventore excepturi tenetur neque eum tempora accusantium non quam deleniti quasi, incidunt dolore? Odit, velit ab!"
                        reversed={false}
                    /> */}
                    <h2>
                        Wrong
                        <br />
                        Latitude
                    </h2>
                </div>

                <div className="CallToAction__Beer__Image">
                    {/* Slide up quick */}
                    <img src={BeerImg} alt="Beer"/>
                </div>

                <div className="CallToAction__Beer__Selection2">
                    {/* Slide in left/right */}
                    {/* <Beer
                        icon={Icon}
                        title="Porter"
                        description="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Optio nostrum eligendi nemo unde eos inventore excepturi tenetur neque eum tempora accusantium non quam deleniti quasi, incidunt dolore? Odit, velit ab!"
                        reversed={true}
                    /> */}
                    <h2>
                        Right
                        <br />
                        Attitude
                    </h2>

                </div>
            </div>
        </div>
    );
}