import React, { useEffect, useRef } from 'react';
import { jarallax } from 'jarallax';
import './Jarallax.scss';

export default function Jarallax({ src = "https://source.unsplash.com/random/1280x720", children }) {

    const el = useRef(null);

    useEffect(() => {
        jarallax(el.current, {
            speed: 0.2,
        });
    }, []);

    return (
        <div className="Jarallax" ref={el} style={{backgroundImage: `url('${src}')`}}>
           {children}

           <div className="Jarallax__Shadow" />
        </div>
    );
}