import React from 'react';

const Border = {
    borderBottom: '1px groove #eee',
};

export default function Item ({ full = true, name, type, abv, description }) {
    return (
        <React.Fragment>
            <tr className="Item">
                <td style={full ? {} : Border}><b>{name}</b></td>
                <td style={full ? {} : Border}>{type}</td>
                <td style={full ? {} : Border}>{abv}</td>
            </tr>
            {full && (
                <tr className="Item">
                    <td colSpan="3">
                        <i>{description}</i>
                    </td>
                </tr>
            )}
        </React.Fragment>
    );
}