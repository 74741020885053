import React from 'react';
import './Privacy.scss';

(function (w,d) {
    var loader = function () {
        var s = d.createElement("script");
        var tag = d.getElementsByTagName("script")[0];
        s.src="https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s,tag);
    };
    if(w.addEventListener) {
        w.addEventListener("load", loader, false);
    } else if (w.attachEvent) {
        w.attachEvent("onload", loader);
    } else {
        w.onload = loader;
    }
})(window, document);

export default function Privacy () {
    return (
        <div className="Privacy">
            <div className="container">
                <h1>Privacy Policy</h1>
                <a href="https://www.iubenda.com/privacy-policy/24185156" class="btn iubenda-white iubenda-embed" title="Privacy Policy">
                    Privacy Policy
                </a>
            </div>
        </div>
    );
}
