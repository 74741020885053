import React from 'react';
import './Location.scss';

export default function Location () {
    return (
        <div className="Location">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.0163068403212!2d-92.97734228450176!3d43.6686306791208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87f0d18254e2972d%3A0xa851ff41ca2d7ba5!2sGravity%20Storm%20Brewery%20Cooperative!5e0!3m2!1sen!2sus!4v1576164114884!5m2!1sen!2sus"
                width="500"
                height="400"
                title="Gravity Storm Brewery Cooperative"
                style={{border:0}}
            />
        </div>
    );
}
