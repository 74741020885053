import React from 'react';
import './Terms.scss';

export default function Terms () {
    return (
        <div className="Terms">
            <div className="container">
                <h1>Terms of Use</h1>
                <a href="https://www.termsfeed.com/terms-conditions/33ac2af7d32bb12d6269d8d241fa00fa" className="btn" target="_blank" rel="noopener noreferrer">Terms of Use</a>
            </div>
        </div>
    );
}
