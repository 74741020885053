import React from 'react';
import './Patron.scss';

import BackgroundImage from './gonzalo-remy-JCIJnIXv7SE-unsplash.jpg';

export default function Patron () {
    return (
        <div className="Patron">
            <h1 style={{textAlign: 'center'}}>Becoming a Patron</h1>

            <div className="container">
                <h2>What is a cooperative?</h2>
                <p>
                    A Co-op at its essence is a member owned and directed enterprise that operates
                    for the mutual benefit of all members and others in the community.
                </p>
                <p>
                    Our patrons and investors own the business and have a say in how things unfold at GSBC.
                    Our aim is to make our community a more vibrant place to live by celebrating the intersection of creativity and craft beer.
                    We want to be a place for everyone in the community to come together and celebrate Austin and all it has to offer.
                    Come by for a beer, stay for three.
                </p>
            </div>

            <div className="container">
                <h2>How do I get involved?</h2>
                <p>All it takes is a $200, one-time, payment ($300 for a couple) and you become a Patron-Owner. This gets you:</p>
                <ul>
                    <li>$1 off every beer you purchase.</li>
                    <li>One vote on how things will be run in the co-op.</li>
                    <li>Discounts on merchandise (free glass and shirt on sign-up).</li>
                    <li>Invitation to semi-annual owners-only events.</li>
                    <li>First chance to sign up for public events (beer dinners, brew classes, etc).</li>
                    <li>Pre-sale purchases of special bottle releases.</li>
                    <li>The ultra-cool title: "Brewery Owner".</li>
                </ul>
                <p>An investment of at least $2000 makes you an Investor-Owner and gets you all the above benefits
                    plus a year-end dividend based on the co-ops profits and the amount of you investment.
                    (up to 8% of your investment)
                </p>
            </div>

            <div className="container">
                <h2>Frequently Asked Questions</h2>

                <h3><i>Can I help brew?</i></h3>
                <p>Absolutely, tell us when you're available and we'll set it up.</p>

                <h3><i>Can I tend bar?</i></h3>
                <p>We can always use the help. Just come in for a bit of training and we'll get you behind the bar.</p>

                <h3><i>Can I be on the board of directors?</i></h3>
                <p>You bet. All owners are eligible to run for an open seat on the board.</p>
            </div>

            <div className="Patron__Background" style={{backgroundImage: `url(${BackgroundImage})`}} />
        </div>
    );
}
