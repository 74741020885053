import React from 'react';
import './Trivia.scss';

export default function Trivia () {
    return (
        <div className="Trivia">
           Trivia

        </div>
    );
}