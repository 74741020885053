import React, { useState, useEffect } from 'react';
import './CalendarComp.scss';

const backup = [
    {
        day: "11",
        month: "Jan",
        date: "01/11/2020",
        title: "Rush Hour II (Humane Society Fund Raiser)",
        time: "7pm - 10pm",
        link: "https://www.facebook.com/events/562977757587783/"
    },
    {
        day: "25",
        month: "Jan",
        date: "01/25/2020",
        title: "Dobbins Creek Boys",
        time: "7pm - 10pm",
        link: ""
    },
    {
        day: "1",
        month: "Feb",
        date: "02/01/2020",
        title: "Dariel",
        time: "7pm - 9pm",
        link: ""
    },
    {
        day: "15",
        month: "Feb",
        date: "02/15/2020",
        title: "Thomas Estrem",
        time: "7:30pm - 10pm",
        link: "https://www.facebook.com/events/2513175045673378/"
    },
    {
        day: "29",
        month: "Feb",
        date: "02/29/2020",
        title: "Jeff Humphress",
        time: "7pm - 9pm",
        link: ""
    },
    {
        day: "14",
        month: "Mar",
        date: "03/14/2020",
        title: "Mac & Reino",
        time: "7pm - 10pm",
        link: ""
    },
    {
        day: "28",
        month: "Mar",
        date: "03/28/2020",
        title: "Dylan Fett",
        time: "7pm - 9pm",
        link: ""
    },
    {
        day: "18",
        month: "Apr",
        date: "04/18/2020",
        title: "Jeff Humphress",
        time: "7pm - 9pm",
        link: ""
    },
    {
        day: "9",
        month: "May",
        date: "05/09/2020",
        title: "Ripdoozers",
        time: "7pm - 10pm",
        link: ""
    }
];

function Divider() {
    return (
        <span className="Divider" style={{
            fontSize: '2rem',
        }}>
            |
        </span>
    );
}

export default function CalendarComp ({ short = false }) {

    const [events, setEvents] = useState([]);
    const [sortedEvents, setSortedEvents] = useState([]);

    useEffect(() => {

        fetch('/get-events', { method: 'POST'})
            .then(response => {

                if (response.status !== 200) return setEvents([...backup]);

                response.json().then(result => {
                    if (!result) return setEvents([...backup]);

                    // Filter future dates (event date > current date - 1 day)
                    const currentEvents = result.filter(item => new Date(item.start).getTime() > new Date().getTime() - 86400000);

                    setEvents([...currentEvents]);
                });
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        if (!events.length) return;
        events.sort(function(a,b) {
            return new Date(a.start) - new Date(b.start)
        });
        setSortedEvents([...events]);
    }, [events]);

    return (
        <div className="CalendarComp">
            <ul className="CalendarComp__List">
                {sortedEvents.slice(0, short ? Math.min(sortedEvents.length, 3) : sortedEvents.length).map((event, i) => (
                    <li key={i} className="CalendarComp__List__Item">

                        <span className="CalendarComp__List__Item__Date">
                            {new Date(event.start).toUTCString().split(' ')[2]}
                            {window.innerWidth < 768 ? <span>&nbsp;</span> : <br />}
                            {new Date(event.start).getUTCDate()}
                        </span>

                        <Divider />

                        <span className="CalendarComp__List__Item__Title">
                            {event.title.split('\n')[0]}
                        </span>

                        <Divider />

                        <span className="CalendarComp__List__Item__Time">
                            {event.title.split('\n')[1]}
                        </span>

                        {event.url && (
                            <span className="CalendarComp__List__Item__Link">
                                <a className="btn" href={event.url} target="_blank" rel="noopener noreferrer">
                                    DETAILS
                                </a>
                            </span>
                        )}

                    </li>
                ))}
            </ul>
        </div>
    );
}
