import React from 'react';
import { Link } from "react-router-dom";
import './Sidebar.scss';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBeer, faCalendar, faMap, faHandshake } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../../img/logo.svg';

import Media from '../../../components/Media/Media.jsx';

export default function Sidebar ({ open, HandleSidebar }) {
    return (
        <aside className={`Sidebar ${open && 'open'}`}>
            <nav>
                <Link to="/" className="logo" onClick={HandleSidebar}>
                    <img src={Logo} alt="Gravity Storm Brewery"/>
                </Link>
                <Link to="/menu" onClick={HandleSidebar}>
                    Menu &nbsp; <FontAwesomeIcon icon={faMap} />
                </Link>
                <Link to="/calendar" onClick={HandleSidebar}>
                    Calendar &nbsp; <FontAwesomeIcon icon={faCalendar} />
                </Link>
                <Link to="/contact" onClick={HandleSidebar}>
                    Contact Us &nbsp; <FontAwesomeIcon icon={faBeer} />
                </Link>
                <Link to="/patron" onClick={HandleSidebar}>
                    Become a Patron &nbsp; <FontAwesomeIcon icon={faHandshake} />
                </Link>
                <Media />
            </nav>
        </aside>
    );
}
