import React, { useState, useEffect } from 'react';
import './GrowlersAndCrowlers.scss';

import BackgroundImage from './photo-1539192009026-4163d2da8925.jpg';

const backup = [
    {
        type: "Crowler* (750mL)",
        pricing: "9.00"
    },
    {
        type: "Growler* (64oz)",
        pricing: "15.00"
    },
    {
        type: "Growler* (64oz) (New Fill)",
        pricing: "20.00"
    }
];

export default function GrowlersAndCrowlers () {

    const [crowler, setCrowler] = useState(backup[0]);
    const [growler, setGrowler] = useState(backup[1]);
    const [growlerNF, setGrowlerNF] = useState(backup[2]);

    useEffect(() => {
        fetch('/get-pricing', { method: 'POST' })
            .then(response =>{
                if (response.status !== 200) return setVars(backup);

                response.json().then(result => {
                    if (!result) return setVars(backup);
                    setVars(result);
                });
            })
            .catch(console.error);
    }, []);

    const setVars = data => {
        if (!Array.isArray(data)) {
            setCrowler(backup[0]);
            setGrowler(backup[1]);
            setGrowlerNF(backup[2]);
            return;
        }
        for (let i = 0; i < data.length; i++) {
            if (
                data[i].type.toUpperCase().includes('CROWLER* (750ML)')
            ) setCrowler(data[i]);
            if (
                data[i].type.toUpperCase().includes('GROWLER* (64OZ)')
                && !data[i].type.toUpperCase().includes('GROWLER* (64OZ) (NEW FILL)')
            ) setGrowler(data[i]);
            if (
                data[i].type.toUpperCase().includes('GROWLER* (64OZ) (NEW FILL)')
                && !data[i].type.toUpperCase().includes('CROWLER* (750ML)')
            ) setGrowlerNF(data[i]);
        }
    }

    return (
        <div className="GrowlersAndCrowlers">
            <div className="GrowlersAndCrowlers__Content">
                <h2>Growlers and Crowlers</h2>
                <h4>We now have both!</h4>
                <p>64 oz. Growler fill: ${growler.pricing} | New fill: ${growlerNF.pricing}</p>
                <p>750ml Crowlers: ${crowler.pricing}</p>
                <p>We will fill your growler -- as long as it is 64oz and CLEAN</p>
            </div>

            <div className="GrowlersAndCrowlers__Background" style={{backgroundImage: `url(${BackgroundImage})`}} />
        </div>
    );
}
