import React from 'react';
import './Food.scss';

import BackgroundImage from './photo-1548867688-231911e4ba3c.jpg';

export default function Food () {
    return (
        <div className="Food">
            <div className="Food__Content">
                <h2>Food</h2>
                <h4>We have popcorn.</h4>
                <p>But fear not, we are more than happy for you to bring in food from any of the excellent restaurants on Main St.</p>
                <p>Menus are on the Historical Society display.</p>
            </div>

            <div className="Food__Background" style={{backgroundImage: `url(${BackgroundImage})`}} />
        </div>
    );
}
