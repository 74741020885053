import React from 'react';
import { Link } from 'react-router-dom';
import './Information.scss';

export default function Information () {

    return (
        <div className="Information">

            <div className="Information__Content">
                <p>
                    We are a cooperatively owned nano-brewery and taproom. We are still accepting owners, both Patron and Investor.
                    <br />&nbsp;<br />
                    Stop in to see what we've got on tap!
                </p>

                <Link to="/contact" className="btn">Get In Touch</Link>
                <Link to="/patron" className="btn">Become an Owner</Link>
            </div>

        </div>
    );
}
