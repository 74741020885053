import React from 'react';
import './Burger.scss';

export default function Burger ({ sidebarOpen, HandleSidebar }) {
    return (
        <button className={`Burger ${sidebarOpen && 'open'}`}
            type="button"
            title="Menu"
            onClick={HandleSidebar}
        >
            <div className="box">
                <div className="inner"></div>
            </div>
        </button>
    );
}
