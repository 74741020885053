import React, { useState, useEffect } from 'react';

import Navbar from './Navbar/Navbar.jsx';
import Burger from './Burger/Burger.jsx';
import Sidebar from './Sidebar/Sidebar.jsx';
import ScrollToTop from './ScrollToTop/ScrollToTop.jsx';

let pathname;
let innerHeight;

export default function Navigation () {

    const [navVisible, setNavVisible] = useState(pathname !== '/');
    const [sttVisible, setSTTVisible] = useState(false);
    const [scroll, setScroll] = useState(0);
    const [sideOpen, setSideOpen] = useState(false);

    useEffect(() => {
        pathname = window.location.pathname;
        innerHeight = window.innerHeight;

        if (pathname === '/' && window.scrollY < (innerHeight * .25)) return setNavVisible(false);
        return setNavVisible(true);
    }, []);

    onscroll = () => {
        const scrollY = window.scrollY;
        setSTTVisible(
            scrollY > innerHeight * 2 &&
            scroll > scrollY
        );

        if (pathname === '/' && scrollY < (innerHeight * .25)) return setNavVisible(false);

        setScroll(scrollY);
        setNavVisible(scroll > scrollY);
    }

    const ToggleSidebar = () => setSideOpen(!sideOpen);

    return (
        <div className="Navigation">
            <Navbar visible={navVisible} />
            <Burger HandleSidebar={ToggleSidebar} sidebarOpen={sideOpen} />
            <Sidebar open={sideOpen} HandleSidebar={ToggleSidebar} />
            <ScrollToTop visible={sttVisible} />
        </div>
    );
}
