import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Error404.scss';

import Logo from '../../img/logo.svg';

const routes = [
    'home',
    'menu',
    'contact',
    'calendar',
    'patron',
    'terms',
    'privacy',
]

function levenshtein(a, b){
	let tmp;
	if (a.length === 0) { return b.length; }
	if (b.length === 0) { return a.length; }
	if (a.length > b.length) { tmp = a; a = b; b = tmp; }

    let i;
    let j;
    let res;
    const alen = a.length;
    const blen = b.length;
    const row = Array(alen);
	for (i = 0; i <= alen; i++) {
        row[i] = i;
    }

	for (i = 1; i <= blen; i++) {
		res = i;
		for (j = 1; j <= alen; j++) {
			tmp = row[j - 1];
			row[j - 1] = res;
			res = b[i - 1] === a[j - 1] ? tmp : Math.min(tmp + 1, Math.min(res + 1, row[j] + 1));
		}
	}
	return res;
}

export default function Error404 () {

    const [recommended, setRecommended] = useState('');

    useEffect(() => {
        const route = window.location.pathname.slice(1, 20);
        const entries = [];

        for (let i = 0; i < routes.length; i++) {
            // console.log(route, routes[i], levenshtein(route, routes[i]));
            entries.push(levenshtein(route, routes[i]));
        }

        const winner = entries.indexOf(Math.min.apply(null, entries));
        setRecommended(routes[winner]);

    }, []);

    return (
        <div className="Error404">

            <div className="Error404__Content">

                <h1>404 - Page Not Found</h1>

                <p>
                    Did you mean to go to
                    our <Link to={recommended === 'home' ? '/' : `/${recommended}`}
                        style={{ textDecoration: 'underline'}}
                    >
                        {recommended === 'home' ?
                            'Home' :
                            recommended.slice(0, 1).toUpperCase() + recommended.slice(1)
                        }
                    </Link> page?
                </p>

                <button className="btn" type="button"
                    onClick={e => {
                        e.preventDefault();
                        window.history.back();
                    }}
                >Go Back</button>

            </div>

            <div className="Error404__Background" style={{backgroundImage: `url(${Logo})`}} />
        </div>
    );
}