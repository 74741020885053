import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../img/logo.svg';
import Tagline from '../../img/logo.png';
import Storefront from '../../img/storefront.jpg';

export default function Header () {
    return (
        <header className="Header">
            <div className="Header__Content">

                <div className="Header__Content__Location">
                    <a href="https://goo.gl/maps/PUtBqWiYgHU5vNwx9" target="_blank" rel="noopener noreferrer" title="Google Maps">
                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                        &nbsp; 309 Main St N
                        <br />
                        Austin, MN 55912
                    </a>
                </div>

                <div className="Header__Content__Column">

                    <img className="logo" src={Logo} alt="Gravity Storm Brewery" />

                    <img className="tagline" src={Tagline} alt="Gravity Storm Brewery Cooperative"/>

                    <Link to="/contact" className="more btn">
                        Find Out More
                    </Link>
                </div>

                <div className="Header__Content__Underlay" style={{
                    backgroundImage: `url(${Storefront})`,
                }}></div>

            </div>
        </header>
    );
}