import React from 'react';
import './Media.scss';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faUntappd } from '@fortawesome/free-brands-svg-icons';
import { faMapMarked, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default class Media extends React.Component {
    render () {
        return (
            <div className="Media">
                <a href="https://www.facebook.com/GravityStormBrewery/" target="_blank" rel="noopener noreferrer" title='Facebook'>
                    <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://untappd.com/GravityStormBrewery" target="_blank" rel="noopener noreferrer" title='Untapped'>
                    <FontAwesomeIcon icon={faUntappd} />
                </a>
                <a href="https://goo.gl/maps/MWsueV73jPgBwfyJ7" target="_blank" rel="noopener noreferrer" title='Google Maps'>
                    <FontAwesomeIcon icon={faMapMarked} />
                </a>
                <a href="tel:507-396-2107" title='507-396-2107'>
                    <FontAwesomeIcon icon={faPhone} />
                </a>
                <a href="mailto:brian@gravitystormbrewery.com" title="brian@gravitystormbrewery.com">
                    <FontAwesomeIcon icon={faEnvelope} />
                </a>
            </div>
        );
    }
}