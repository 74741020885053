import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import './Calendar.scss';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

import Modal from './Modal/Modal.jsx';

import BackgroundImage from './photo-1518099074172-2e47ee6cfdc0.jpg';

export default function Calendar() {

    const [events, setEvents] = useState([]);

    const [isModal, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const [time, setTime] = useState('');
    const [start, setStart] = useState('');
    const [link, setLink] = useState('');

    useEffect(() => {

        fetch('/get-events', { method: 'POST'})
            .then(response => {

                if (response.status !== 200) return console.error('Server Error', response.statusText);

                response.json().then(result => {
                    if (!result) return console.error('No Events');

                    setEvents([...result]);
                });
            })
            .catch(console.error);
    }, []);

    const HandleEventClick = evt => {
        evt.jsEvent.preventDefault();

        const titleSplit = evt.event.title.split('\n');

        if (titleSplit.length === 2) {
            setTitle(titleSplit[0]);
            setTime(titleSplit[1]);
        }

        const date = new Date(evt.event.start);
        setStart(date.toDateString());

        setLink(evt.event.url);

        setModal(true);
    };

    return (
        <div className="Calendar">
            <div className="container">
                <h1>Gravity Storm Events</h1>
                <hr />
                <FullCalendar
                    defaultView="dayGridMonth"
                    plugins={[ dayGridPlugin ]}
                    events={events}
                    header={{
                        left: 'today',
                        center: 'title',
                        right: 'prev,next',
                    }}
                    height="parent"
                    contentHeight={600}
                    eventClick={HandleEventClick}
                />
            </div>

            <div className="Calendar__Background" style={{backgroundImage: `url(${BackgroundImage})`}} />

            <Modal
                visible={isModal}
                title={title}
                time={time}
                start={start}
                link={link}
                onClose={() => setModal(false)}
            />
        </div>
    );
}