import React, { useState, useEffect, } from 'react';
import { BrowserRouter, Route, Switch, } from 'react-router-dom';
import '../abstracts/_variables.scss';
import '../abstracts/_mixins.scss';

// Layout
import AppShell from './AppShell/AppShell.jsx';
import Navigation from '../layout/Navigation/Navigation.jsx';
import Footer from '../layout/Footer/Footer.jsx';
import ScrollRestoration from './ScrollRestoration.jsx';

import Bingo from '../components/Bingo/Bingo.jsx';
import Trivia from '../components/Trivia/Trivia.jsx';

import Landing from '../pages/Landing/Landing.jsx';
import Contact from '../pages/Contact/Contact.jsx';
import Calendar from '../pages/Calendar/Calendar.jsx';
import Menu from '../pages/Menu/Menu.jsx';
import Patron from '../pages/Patron/Patron.jsx';
import Terms from '../pages/Terms/Terms.jsx';
import Privacy from '../pages/Privacy/Privacy.jsx';
import Error404 from '../pages/Error404/Error404.jsx';

const Admin = React.lazy(() => import('../pages/Admin/Admin.jsx'));

export default function Router () {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        window.addEventListener('load', handleload);
    }, []);

    useEffect(() => {
        const loader = document.getElementById('loader');
        if (loader) document.body.removeChild(loader);
    }, [loaded]);

    function handleload () {
        setLoaded(true);
    }

    return (
        <BrowserRouter className="Router">
            <ScrollRestoration />
            <AppShell>

                <Switch>
                     {/* LANDING */}
                    <Route exact path="/" component={() => (
                        <React.Fragment>
                            <Navigation />
                            <Landing />
                        </React.Fragment>
                    )} />
                    {/* MENU */}
                    <Route exact path="/menu" component={() => (
                        <React.Fragment>
                            <Navigation />
                            <Menu />
                        </React.Fragment>
                    )} />
                    {/* CALENDAR */}
                    <Route exact path="/calendar" component={() => (
                        <React.Fragment>
                            <Navigation />
                            <Calendar />
                        </React.Fragment>
                    )} />
                    {/* CONTACT */}
                    <Route exact path="/contact" component={() => (
                        <React.Fragment>
                            <Navigation />
                            <Contact />
                        </React.Fragment>
                    )} />
                    {/* PATRON */}
                    <Route exact path="/patron" component={() => (
                        <React.Fragment>
                            <Navigation />
                            <Patron />
                        </React.Fragment>
                    )} />
                    {/* TERMS&CONDITIONS */}
                    <Route exact path="/terms" component={() => (
                        <React.Fragment>
                            <Navigation />
                            <Terms />
                        </React.Fragment>
                    )} />
                    {/* PRIVACY */}
                    <Route exact path="/privacy" component={() => (
                        <React.Fragment>
                            <Navigation />
                            <Privacy />
                        </React.Fragment>
                    )} />
                    {/* BINGO */}
                    <Route exact path="/bingo" component={() => (
                        <React.Fragment>
                            <Navigation />
                            <Bingo />
                        </React.Fragment>
                    )} />
                    {/* TRIVIA */}
                    <Route exact path="/trivia" component={() => (
                        <React.Fragment>
                            <Navigation />
                            <Trivia />
                        </React.Fragment>
                    )} />
                    {/* ADMINISTRATION */}
                    <Route exact path="/admin" component={() => (
                        <React.Suspense fallback={<div>Admin</div>}>
                            <Admin />
                        </React.Suspense>
                    )} />
                    {/* Error - 404 */}
                    <Route component={() => (
                        <React.Fragment>
                            <Navigation />
                            <Error404 />
                        </React.Fragment>
                    )} />
                </Switch>

                <Footer />

            </AppShell>
        </BrowserRouter>
    );
}
