import React from 'react';
import './AppShell.scss';

export default function AppShell ({ children }) {
    return (
        <div className="AppShell">
            {children}
        </div>
    );
}
