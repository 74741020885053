import React from 'react';
import './BeerItForward.scss';

import BackgroundImage from './photo-1494887205043-c5f291293cf6.jpg';

export default function BeerItForward () {
    return (
        <div className="BeerItForward">
            <div className="BeerItForward__Content">
                <h2>Beer It Forward</h2>

                <h4>Buy a beer for a friend, old or new</h4>
                <p>Get a card from your bartender and put it on the board to buy a beer for:</p>
                <p>
                    * someone you know
                    <br />
                    * someone you don't (a veteran, teacher, firefighter, etc.)
                    <br />
                    * something a little different (someone wearing a Buffett shirt, etc.)
                </p>
                <p>Make sure to check the board often to see if someone has bought a beer for you.</p>
                <p>10% of all Beer it Forward purchases go to the Lyle Cancer Auction</p>
            </div>


            <div className="BeerItForward__Background" style={{backgroundImage: `url(${BackgroundImage})`}} />
        </div>
    );
}
