import React from 'react';
import { Link } from 'react-router-dom';
import './Landing.scss';

import Header from '../../components/Header/Header.jsx';
import CallToAction from '../../components/CallToAction/CallToAction.jsx';
import Notice from '../../components/Notice/Notice.jsx';
import Hours from '../../components/Hours/Hours.jsx';
import Location from '../../components/Location/Location.jsx';
import Menu from '../../components/MenuComp/MenuComp.jsx';
import Specials from '../../components/Specials/Specials.jsx';
import CalendarComp from '../../components/CalendarComp/CalendarComp.jsx';
import Information from '../../components/Information/Information.jsx';
import BeerItForward from '../../components/BeerItForward/BeerItForward.jsx';
import GrowlersAndCrowlers from '../../components/GrowlersAndCrowlers/GrowlersAndCrowlers.jsx';
import Food from '../../components/Food/Food.jsx';
import Gallery from '../../components/Gallery/Gallery.jsx';

import Jarallax from '../../components/Jarallax/Jarallax.jsx';
import FlightBackground from '../../img/flight.jpg';
import SpecialsBackground from './photo-1571613316887-6f8d5cbf7ef7.jpg';
import AboutUsBackground from './photo-1569092433665-20d853fd0934.jpg';
import CalendarBackground from './photo-1518176258769-f227c798150e.jpg';
import LocationBackground from './photo-1546622891-02c72c1537b6.jpg';

export default function Landing () {
    return (
        <div className="Landing">
            {/* HEADER */}
            <Header />

            {/* NOTICE */}
            <Notice />

            {/* HOURS BREAK */}
            <div className="Landing__Hours">
                <Jarallax src={FlightBackground}>
                    <Hours />
                </Jarallax>
            </div>

            {/* CALL TO ACTION */}
            <CallToAction />

            {/* SPECIALS BREAK */}
            <div className="Landing__Specials">
                <Jarallax src={SpecialsBackground}>
                    <Specials />
                </Jarallax>
            </div>

            {/* MENU & PRICING */}
            <Menu full={false} />

            {/* EMAIL BREAK */}
            <div className="Landing__Information">
                <Jarallax src={AboutUsBackground}>
                    <Information />
                </Jarallax>
            </div>

            {/* CALENDAR */}
            <div className="Landing__Calendar">
                <div className="Landing__Calendar__Content">
                    <h2>Upcoming Events</h2>
                    <CalendarComp short={true} />

                    <div className="container">
                        <Link className="btn" to="/calendar">
                            View Full Calendar
                        </Link>
                    </div>
                </div>
                <div className="Landing__Calendar__Background" style={{backgroundImage: `url(${CalendarBackground})`}} />
            </div>

            <div className="Landing__Extras">
                <div className="Landing__Extras__Content">
                    <BeerItForward />
                    <GrowlersAndCrowlers />
                    <Food />
                </div>

                <div className="Landing__Extras__Background" />
            </div>

            {/* GALLERY */}
            <Gallery />

            <div className="Landing__Location">
                <div className="Landing__Location__Content">
                    <h2>Find Us in Austin</h2>
                    <Location />
                </div>

                <div className="Landing__Location__Background" style={{backgroundImage: `url(${LocationBackground})`}} />

            </div>

        </div>
    );
}