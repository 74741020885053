import React from 'react';
import './Contact.scss';

import ContactForm from '../../components/ContactForm/ContactForm.jsx';

import Logo from '../../img/logo.png';
import BackgroundImage from './photo-1535958636474-b021ee887b13.jpg';

export default function Contact () {
    return (
        <div className="Contact">

            <div className="container">

                <div className="Contact__Left">
                    <img src={Logo} alt="Gravity Storm Brewery"/>
                    <p>
                        We are a cooperatively owned nano-brewery and taproom. We are still accepting owners, both Patron and Investor.
                        <br />
                        Stop in to see what we've got on tap!
                    </p>
                </div>

                <div className="Contact__Right">
                    <h3>Have a question?</h3>
                    <h2>Get In Touch</h2>
                    <ContactForm />
                </div>

            </div>

            <div className="Contact__Background" style={{backgroundImage: `url(${BackgroundImage})`}} />

        </div>
    );
}
