import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './MenuComp.scss';

import BeerTable from './BeerTable/BeerTable.jsx';
import PricingTable from './PricingTable/PricingTable.jsx';

import WoodBackground from '../../img/WoodFine.jpg';

export default function MenuComp ({ full }) {

    const [readable, setReadable] = useState(false);

    return (
        <section
            className={`MenuComp ${readable && 'readable'}`}
            style={{
                backgroundImage: `url(${WoodBackground})`,
                paddingTop: full ? '6rem' : '2rem',
            }}
        >
            <BeerTable full={full} />

            <div className="container">
                <button className="toggle-font-btn btn" type="button" onClick={() => setReadable(!readable)}>
                    {readable ? <span>Authentic Menu</span> : <span>Trouble reading the menu? Click Here</span>}
                </button>
            </div>

            <PricingTable full={full} />

            {!full && (
                <div className="container">
                    <Link className="btn" to="/menu">
                        View Full Menu &amp; Pricing
                    </Link>
                </div>
            )}

        </section>
    );
}
