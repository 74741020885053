import React, { useState, useEffect } from 'react';
import './Specials.scss';

const backup = {
    flights: '$7',
    pints: '$6',
    hours: '3pm - 7pm'
};

function Divider() {
    return (
        <div className="Divider">
            |
        </div>
    );
}

export default function Specials () {

    const [specialsData, setSpecialsData] = useState({});

    useEffect(() => {
        fetch('/get-specials-data', { method: 'POST'})
            .then(response => {
                if (response.status !== 200) return setSpecialsData(backup);

                response.json().then(result => {
                    if (!result.specialsData) return setSpecialsData(backup);
                    setSpecialsData(result.specialsData);
                });
            })
            .catch(console.error);
    }, []);

    return (
        <div className="Specials">
            <div className="Specials__1">
                <h2>Thursday Specials</h2>
                <p>Flights: {specialsData.flights} &nbsp; | &nbsp; Pints: {specialsData.pints}</p>
            </div>

            <Divider />

            <div className="Specials__2">
                <h2>Teacher Appreciation Friday</h2>
                <p>Buy One, Get One Free
                    <br />
                    {specialsData.hours}
                </p>
            </div>
        </div>
    );
}
