import React from 'react';
import './Menu.scss';

import MenuComp from '../../components/MenuComp/MenuComp.jsx';

export default function Menu () {
    return (
        <div className="Menu">
            <MenuComp full={true} />
        </div>
    );
}
