import React from 'react';
import './Bingo.scss';

import BingoImage from './bingo.png';

export default function Bingo () {
    return (
        <div className="Bingo">
            <div className="Bingo__Copy">
                <h1>Austin Bingo</h1>
                <h4>Monday Jan 6th at 7:00 at Gravity Storm</h4>
                <p>Free to play</p>
                <p>Your random card is right on your phone.</p>
                <p>Win Gravity Storm beer and prizes from local businesses.</p>
                <p>Last game is "Black Out" for big prizes and a progressive bonus.</p>
            </div>
            <div className="Bingo__Image">
                <img src={BingoImage} alt="Bingo"/>
            </div>
        </div>
    );
}