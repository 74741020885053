import React, { useState, useEffect } from 'react';
import './BeerTable.scss';

import Chalkboard from '../../../img/ChalkboardBackground.jpg';
import Item from './Item/Item.jsx';

const backup = [
    {
        name: "Dreamsicle",
        type: "Light Ale w/ Blood Orange",
        abv: "4.9%",
        description: "A light ale with an addition of blood orange puree."
    },
    {
        name: "Great Heart",
        type: "Red IPA",
        abv: "6.5%",
        description: "Kind of a throwback to IPA's of the 2000's. An addition of crystal malt gives this a bit more sweetness and body. But that sweetness is countered by 70 IBU of hop goodness. Dry hopped with Mosaic and Galaxy."
    },
    {
        name: "Fins",
        type: "American Pilsner",
        abv: "5.2%",
        description: "Our take on Landshark®. \" The lightest beer we have. \" Don't tell Jimmy but we think ours is quite a bit better."
    },
    {
        name: "Pencil Thin Mustache",
        type: "Midwest IPA",
        abv: "6.8%",
        description: "Early dry-hopping gives this beer a bit of haze and a really nice aroma and finish. Only 45 IBU. Mosaic and Citra hops provide the punch."
    },
    {
        name: "Domino College",
        type: "Schwarzbier",
        abv: "5.3%",
        description: "Schwarz means black in German. This is a dark lager brewed with a very similar grain bill to our brown ale, just a little more crisp and clean. Look for the subtle roast and chocolate notes in the aroma and flavor."
    },
    {
        name: "Saint Somewhere",
        type: "Sweet Stout",
        abv: "4.7%",
        description: "Don't fear the dark! This is smooth, creamy, and easy drinking."
    },
    {
        name: "Gravitator *",
        type: "Doppelbock",
        abv: "8.6%",
        description: "Liquid Bread. Big and malty and perfect for a Minnesota autumn."
    },
    {
        name: "Blue Heaven Rendezvous",
        type: "Blueberry Sour",
        abv: "3.7%",
        description: "Berliner Weisse style with blueberry puree added post-fermentation. The result is a very tart, fruity drink. Give it a try if you're more of a wine than beer perons - or not."
    }
];

/* OLD
    {
        "name": "Mañana",
        "type": "Vienna Lager",
        "abv": "5.3%",
        "description": "Amber colored, light bready aroma. Similar to a Marzen with a bit less caramel character and a bit firmer bitterness. Have one hoy y mañana."
    },
    {
        "name": "Simply Complicated *",
        "type": "Maple Ale",
        "abv": "10.1%",
        "description": "A collaboration with Sapthre Maple Syrup. Two gallons of maple syrup and bourbon soaked oak make this one unique beer that we can probably never replicate - so enjoy it while it lasts!"
    }
*/

export default function BeerTable ({ full }) {

    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch('/get-beer', { method: 'POST' })
            .then(response =>{
                if (response.status !== 200) return setItems([...backup]);

                response.json().then(result => {
                    if (!result) return setItems([...backup]);
                    setItems([...result]);
                });
            })
            .catch(console.error);
    }, []);

    return (
        <table className="BeerTable" style={{backgroundImage: `url(${Chalkboard})`}}>
            <thead>
                <tr style={{fontSize: '2rem'}}>
                    <th colSpan="3">Gravity Storm Beers</th>
                </tr>
                <tr style={{textAlign: 'left', fontSize: '1.5rem'}}>
                    <th>Name</th>
                    <th>Type</th>
                    <th>ABV</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item, i) => (
                    <Item key={i}
                        name={item.name}
                        type={item.type}
                        abv={item.abv}
                        full={full}
                        description={item.description}
                    />
                ))}
            </tbody>
        </table>
    );
}