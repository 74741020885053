import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import './Gallery.scss';

// const images = [
//     {
//         original: 'https://picsum.photos/id/1018/1000/600/',
//         thumbnail: 'https://picsum.photos/id/1018/250/150/',
//         description: 'Image 1',
//     },
//     {
//         original: 'https://picsum.photos/id/1015/1000/600/',
//         thumbnail: 'https://picsum.photos/id/1015/250/150/',
//         description: 'Image 2',
//     },
//     {
//         original: 'https://picsum.photos/id/1019/1000/600/',
//         thumbnail: 'https://picsum.photos/id/1019/250/150/',
//         description: 'Image 3',
//     },
//     {
//         original: 'https://scontent-msp1-1.xx.fbcdn.net/v/t1.6435-9/167915951_1242722662872697_2544158956094858374_n.jpg?_nc_cat=105&ccb=1-3&_nc_sid=8bfeb9&_nc_ohc=hPrWRVgQrFoAX9Ggs9l&_nc_oc=AQk6S2f2oRB7t1ILixn9l3hfXMAziHehwHNgP2yzGKvawZWVYfRtGrD4X50-gaC9lOA&_nc_ht=scontent-msp1-1.xx&oh=13b9330dd70a51125f8366d027f123c9&oe=6090CAAF',
//         thumbnail: 'https://scontent-msp1-1.xx.fbcdn.net/v/t1.6435-9/167915951_1242722662872697_2544158956094858374_n.jpg?_nc_cat=105&ccb=1-3&_nc_sid=8bfeb9&_nc_ohc=hPrWRVgQrFoAX9Ggs9l&_nc_oc=AQk6S2f2oRB7t1ILixn9l3hfXMAziHehwHNgP2yzGKvawZWVYfRtGrD4X50-gaC9lOA&_nc_ht=scontent-msp1-1.xx&oh=13b9330dd70a51125f8366d027f123c9&oe=6090CAAF',
//         description: '',
//     }
// ];

export default function Gallery () {

    const [gallery, setGallery] = useState({});

    useEffect(() => {
        fetch('/get-gallery', { method: 'POST'})
            .then(response => {
                if (response.status !== 200) return setGallery(null);

                response.json().then(result => {
                    if (!result) return setGallery(null);

                    setGallery([...result].map(item => {
                        return {
                            original: item.url,
                            thumbnail: item.url,
                            description: item.description,
                        };
                    }));
                });
            })
            .catch(console.error);
    }, []);

    return (
        <div className="Gallery" style={{display: `${gallery && gallery.length ? 'block' : 'none'}`}}>
            <div className="Gallery__Content">
                <h2>Gallery</h2>
                <ImageGallery items={gallery && gallery.length ? gallery : []} />
                {/* <ImageGallery items={images} /> */}
            </div>
        </div>
    );
}
